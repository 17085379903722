import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import axios from "axios";
import qs from 'qs';
import './App.css';
import {PoolxLogoBlack} from "./utils/utils";

interface AppProps {
    oauthParams: Map<string, string | null>
}

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.bazitech.se/">
                BaziTech AB
            </Link>{' '}
            {'2020–'}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

function App({oauthParams}: AppProps) {
    const isDisabled = !oauthParams || oauthParams.size === 0;
    if (isDisabled) {
        window.open('https://www.bazitech.se/', '_self');
    }

    const [loggingIn, setLoggingIn] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoggingIn(true);

        const formData = new FormData(event.currentTarget);
        oauthParams.set('username', formData.get('email')!.toString())
        oauthParams.set('password', formData.get('password')!.toString())

        const options = {
            maxRedirects: 0,
            method: 'POST',
            data: qs.stringify(Object.fromEntries(oauthParams)),
            url: process.env.REACT_APP_OIDC_ENDPOINT,
        };
        axios(options).then((res) => {
            window.open(res.data, '_self');
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setLoggingIn(isDisabled);
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{m: 1,  height: 250}}>
                        {PoolxLogoBlack(150)}
                    </Box>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                        {/*    label="Remember me"*/}
                        {/*/>*/}
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={loggingIn}
                            sx={{mt: 3, mb: 2}}
                        >
                            Sign In
                        </LoadingButton>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Link href="#" variant="body2">*/}
                            {/*        {"Don't have an account? Sign Up"}*/}
                            {/*    </Link>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}

export default App;
